'use client';

import {
  GTMEvent,
  Product,
  GA4EventParams,
  OrderItem
} from '@akinon/next/types';
import { BasketItem, BreadcrumbResultType, Order } from '@akinon/next/types/commerce';
import { createHash } from 'crypto';

interface GTMLoginRegisterUserProps {
  action: string;
  userPhone: string;
  userMail: string;
  userId: string;
}

interface GTMPromotionProps {
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: string;
}

export const pushPageView = (url: string) => {
  pushEventGA4('page_view', {
    page_location: url
  });
};

export const pushPageInfo = (data: {
  pageType?: string;
  pageName?: string;
  pageUrl?: string;
  breadcrumb?: BreadcrumbResultType[];
}) => {
  const { pageType, pageName, pageUrl, breadcrumb } = data;
  let subPageType = '';

  if (breadcrumb?.length === 0) {
    subPageType = pageName;
  }

  if (breadcrumb?.length > 1) {
    subPageType = breadcrumb[breadcrumb.length-2].label;
  }else {
    breadcrumb && (subPageType = breadcrumb[0]?.label);
  }

  pushEventGA4(
    'pageinfo',
    {
      pageType: pageType ?? '',
      subPageType: subPageType ?? '',
      pageName: pageName ?? '',
      pageUrl: pageUrl ?? ''
    }
  );
};

export const pushProductListProductViewed = (products: Product[]) => {
  const { item_list_id, item_list_name } = getProductListInfo();
  const items = products.map((product) =>
    createEcommerceItem({
      product
    })
  );
  pushEventGA4(
    'view_item_list',
    {
      ecommerce: {
        item_list_id,
        item_list_name,
        items
      }
    },
    true
  );
};

export const pushProductClicked = (product: Product) => {
  const { item_list_id, item_list_name } = getProductListInfo();
  const item = createEcommerceItem({ product });
  addProductToLocalStorage(product.pk, item_list_id, item_list_name);
  pushEventGA4(
    'select_item',
    {
      ecommerce: {
        item_list_id,
        item_list_name,
        items: [item]
      }
    },
    true
  );
};

export const pushProductViewed = (product: Product) => {
  const item = createEcommerceItem({ product });
  pushEventGA4(
    'view_item',
    {
      ecommerce: {
        currency: product?.currency_type.toUpperCase(),
        value: Number(product?.retail_price),
        items: [item]
      }
    },
    true
  );
};

export const pushAddToCart = (product: Product, quantity: number) => {
  const item = createEcommerceItem({ product, quantity });
  pushEventGA4(
    'add_to_cart',
    {
      ecommerce: {
        currency: product?.currency_type?.toUpperCase(),
        value: Number(product?.price) * quantity,
        items: [item]
      }
    },
    true
  );
};

export const pushRemoveFromCart = (product: Product, quantity: number) => {
  const item = createEcommerceItem({ product, quantity });
  pushEventGA4(
    'remove_from_cart',
    {
      ecommerce: {
        currency: product?.currency_type.toUpperCase(),
        value: Number(product?.price) * quantity,
        items: [item]
      }
    },
    true
  );
};

export const pushAddToWishlist = (product: Product) => {
  const item = createEcommerceItem({ product});
  pushEventGA4(
    'add_to_wishlist',
    {
      ecommerce: {
        currency: product?.currency_type?.toUpperCase(),
        value: Number(product?.price),
        items: [item]
      }
    },
    true
  );
};

export const pushCartView = (data: {
  basketItems: BasketItem[];
  totalAmount: number;
  voucherCode?: string;
}) => {
  const { basketItems, totalAmount, voucherCode } = data;
  const items = basketItems.map((basketItem) =>
    createEcommerceItem({
      product: basketItem.product,
      quantity: basketItem.quantity,
      coupon: voucherCode || ''
    })
  );
  pushEventGA4(
    'view_cart',
    {
      ecommerce: {
        currency: basketItems[0]?.product?.currency_type.toUpperCase(),
        value: totalAmount,
        items,
        coupon: voucherCode || ''
      }
    },
    true
  );
};

export const pushBeginCheckout = (data: {
  basketItems: BasketItem[];
  totalAmount: number | string;
  voucherCode?: string;
}) => {
  const { basketItems, totalAmount, voucherCode } = data;
  const items = basketItems?.map((basketItem) =>
    createEcommerceItem({
      product: basketItem.product,
      quantity: basketItem.quantity,
      coupon: voucherCode
    })
  );
  pushEventGA4(
    'begin_checkout',
    {
      ecommerce: {
        currency:
          basketItems && basketItems[0]?.product?.currency_type.toUpperCase(),
        value: totalAmount,
        coupon: voucherCode || '',
        items
      }
    },
    true
  );
};

export const pushAddShippingInfo = (data: {
  basketItems: BasketItem[];
  totalAmount: number | string;
  voucherCode?: string;
  shippingTier?: string;
}) => {
  const { basketItems, totalAmount, voucherCode, shippingTier } = data;
  const items = basketItems?.map((basketItem) =>
    createEcommerceItem({
      product: basketItem?.product,
      quantity: basketItem?.quantity,
      coupon: voucherCode
    })
  );
  pushEventGA4(
    'add_shipping_info',
    {
      ecommerce: {
        currency: basketItems[0]?.product?.currency_type.toUpperCase(),
        value: totalAmount,
        coupon: voucherCode || '',
        shipping_tier: shippingTier || '',
        items
      }
    },
    true
  );
};

export const pushAddPaymentInfo = (data: {
  basketItems: BasketItem[];
  totalAmount: number | string;
  voucherCode?: string;
  paymentType?: string;
}) => {
  const { basketItems, totalAmount, voucherCode, paymentType } = data;
  const items = basketItems.map((basketItem) =>
    createEcommerceItem({
      product: basketItem?.product,
      quantity: basketItem?.quantity,
      coupon: voucherCode
    })
  );
  pushEventGA4(
    'add_payment_info',
    {
      ecommerce: {
        currency: basketItems[0]?.product?.currency_type.toUpperCase(),
        value: totalAmount,
        coupon: voucherCode || '',
        payment_type: paymentType || '',
        items
      }
    },
    true
  );
};

export const pushPurchaseEvent = (data: { order: Order }) => {
  const { order } = data;
  const voucherCode = localStorage?.getItem('voucher_code');
  const items = order?.orderitem_set?.map((orderItem) => {
    const formattedProduct = {
      ...orderItem?.product,
      price: orderItem?.price,
      retail_price: orderItem?.retail_price,
      attributes: {
        ...orderItem?.product?.attributes,
        integration_urun_grubu:
          orderItem?.product?.attributes?.integration_urun_grubu
      },
      category: {
        name: orderItem?.product?.category?.name
      }
    };

    const ecommerceItem = createEcommerceItem({
      product: formattedProduct,
      quantity: Number(orderItem.quantity),
      coupon: voucherCode || ''
    });

    return ecommerceItem;
  });
  pushEventGA4('purchase', {
    ecommerce: {
      transactions_id: order?.number,
      value: Number(order?.amount),
      tax: Number(order?.orderitem_set[0]?.tax_rate),
      shipping: Number(order?.shipping_amount),
      currency: order?.currency?.value?.toUpperCase(),
      coupon: voucherCode || '',
      items
    }
  });
  localStorage.removeItem('voucher_code');
};

export const pushRefundEvent = (data: {
  orderItems: OrderItem[];
  transactions_id: string;
  totalAmount: number;
  shippingAmount: number;
}) => {
  const { orderItems, transactions_id, totalAmount, shippingAmount } = data;
  const items = orderItems?.map((orderItem) =>
    createEcommerceItem({
      product: orderItem?.product,
      quantity: Number(orderItem?.quantity)
    })
  );
  pushEventGA4('refund', {
    commerce: {
      currency: orderItems[0]?.product?.price_currency?.toUpperCase(),
      transactions_id,
      value: totalAmount,
      coupon: '',
      shipping: shippingAmount,
      tax: orderItems[0]?.tax_rate,
      items
    }
  });
};

export const pushViewPromotion = (promotion: GTMPromotionProps) => {
  pushEventGA4('view_promotion', {
    ecommerce: {
      creative_name: promotion?.creative_name,
      creative_slot: promotion?.creative_slot,
      promotion_id: promotion?.promotion_id,
      promotion_name: promotion?.promotion_name
    }
  });
};

export const pushSelectPromotion = (promotion: GTMPromotionProps) => {
  pushEventGA4('select_promotion', {
    ecommerce: {
      creative_name: promotion?.creative_name,
      creative_slot: promotion?.creative_slot,
      promotion_id: promotion?.promotion_id,
      promotion_name: promotion?.promotion_name
    }
  });
};

export const pushLogin = (user: GTMLoginRegisterUserProps) => {
  pushEventGA4('login_success', {
    user
  });
};

export const pushRegister = (user: GTMLoginRegisterUserProps) => {
  pushEventGA4('register_success', {
    user
  });
};

export const pushEvent = (event: GTMEvent) => {
  const { Category = 'Enhanced Ecommerce', Value = 0, ...rest } = event;

  window.dataLayer.push({ Category, Value, ...rest });
};

export const pushEventGA4 = (
  eventAction: string,
  eventParams: GA4EventParams,
  pushEcommerceNull?: boolean
) => {
  if (pushEcommerceNull) {
    window.dataLayer.push({
      ecommerce: null
    });
  }
  window.dataLayer.push({
    event: eventAction,
    ...eventParams
  });
};

export const priceDiscountPercentage = (retailPrice: string, price: string) => {
  price = Number(price).toFixed();
  retailPrice = Number(retailPrice).toFixed();
  const priceDiff = Number(retailPrice) - Number(price);
  return ((priceDiff / Number(retailPrice)) * 100).toFixed();
};

export const priceDiscountAmount = (retailPrice: string, price: string) => {
  price = Number(price).toFixed(2);
  retailPrice = Number(retailPrice).toFixed(2);
  return (Number(retailPrice) - Number(price)).toFixed(2);
};

export const createEcommerceItem = (data: {
  product: Product;
  quantity?: number;
  coupon?: string;
}) => {
  const { product, quantity, coupon } = data;
  let item_list_id = '';
  let item_list_name = '';

  const localStorageItem = getProductFromLocalStorage(product?.pk);
  if (localStorageItem) {
    ({ item_list_id, item_list_name } = getProductFromLocalStorage(
      product?.pk
    ));
  } else {
    ({ item_list_id, item_list_name } = getProductListInfo());
  }

  return {
    item_id: product?.attributes?.integration_ean || '',
    item_name: product?.name,
    affiliation: 'PBM Online',
    coupon: coupon || '',
    discount: Number(
      priceDiscountAmount(product?.retail_price, product?.price)
    ),
    item_brand: 'Paşabahçe',
    item_category: product?.attributes?.integration_urun_grubu || '',
    item_category2: product?.attributes?.item_category2 || '',
    item_category3: product?.attributes?.item_category3 || '',
    item_category4: product?.attributes?.item_category4 || '',
    item_category5: product?.attributes?.item_category5 || '',
    item_list_id,
    item_list_name,
    item_variant: product?.attributes_kwargs?.integration_renk?.label,
    price: Number(product?.price),
    quantity: quantity || 1
  };
};

export const sha256 = (value: string) => {
  return createHash('sha256').update(value).digest('hex');
};

const addProductToLocalStorage = (product, itemListId, itemListName) => {
  const productListInfos =
    JSON.parse(localStorage.getItem('productListInfos')) || [];

  const productExists = productListInfos.some(
    (p) => p.product_id === product.pk
  );

  if (!productExists) {
    if (productListInfos.length >= 50) {
      productListInfos.shift();
    }

    productListInfos.push({
      item_list_id: itemListId,
      item_list_name: itemListName,
      product_id: product.product_id
    });

    localStorage.setItem('productListInfos', JSON.stringify(productListInfos));
  }
};

const getProductFromLocalStorage = (productId) => {
  const productListInfos =
    JSON.parse(localStorage.getItem('productListInfos')) || [];
  return productListInfos.find((product) => product.product_id === productId);
};

const getProductListInfo = () => {
  const productListInfo =
    JSON.parse(localStorage.getItem('productListInfo')) || [];
  const item_list_id = productListInfo?.item_list_id || '';
  const item_list_name = productListInfo?.item_list_name || '';
  return { item_list_id, item_list_name };
};
