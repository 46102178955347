'use client';

import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { pushSelectPromotion, pushViewPromotion } from '@theme/utils/gtm';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function HomeSingleBanner({ data }) {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);

  const promotionEvent = useMemo(
    () => ({
      creative_name:
        data?.attributes?.home_single_banner?.kwargs?.value?.desktop_image?.url,
      creative_slot: `Home Single Banner 1`,
      promotion_id: `home-single-banner_1`,
      promotion_name: data?.attributes?.home_single_banner?.value?.title
    }),
    [data]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);

  const handleLinkClick = () => {
    pushSelectPromotion(promotionEvent);
  };

  return (
    <div ref={ref} className="border-b border-lightBlue py-[40px] md:py-[71px]">
      <div className="relative mx-2 flex h-[212px] items-start justify-center sm:container md:h-[192px] md:w-full">
        <div className="hidden h-full w-full lg:block">
          <Link
            className="h-full w-full"
            href={data?.attributes?.home_single_banner?.value?.url}
            onClick={handleLinkClick}
          >
            <Image
              className="h-full w-full object-cover object-center"
              src={
                data?.attributes?.home_single_banner?.kwargs?.value
                  ?.desktop_image?.url ?? '/noimage.jpg'
              }
              fetchPriority="high"
              loading="eager"
              alt={
                data?.attributes?.home_single_banner?.value?.image_alt ??
                'home-single-banner-mobile-image'
              }
              width={1292}
              height={192}
              crop='none'
            />
          </Link>
        </div>
        <div className="block h-full w-full lg:hidden">
          <Link
            className="h-full w-full"
            href={data?.attributes?.home_single_banner?.value?.url}
            onClick={handleLinkClick}
          >
            <Image
              fetchPriority="high"
              loading="eager"
              className="h-full w-full object-cover object-center"
              src={
                data?.attributes?.home_single_banner?.kwargs?.value
                  ?.mobile_image?.url ?? '/noimage.jpg'
              }
              alt={
                data?.attributes?.home_single_banner?.value?.mobile_image_alt ??
                'home-single-banner-desktop-image'
              }
              aspectRatio={375 / 200}
              sizes="375px"
              fill
            />
          </Link>
        </div>
        <div className="absolute flex items-center justify-center py-4 md:py-[30px]">
          <div className="flex w-full flex-col items-center justify-start md:h-[132px]">
            <h2
              {...(data?.attributes?.home_single_banner?.value?.title_color && {
                style: {
                  color:
                    data?.attributes?.home_single_banner?.value?.title_color
                }
              })}
              className="camelcase flex h-[72px] items-center text-center text-[28px] font-normal leading-[1.3] md:h-[44px] md:text-[32px] lg:mt-0"
            >
              {data?.attributes?.home_single_banner?.value?.title}
            </h2>
            <p
              {...(data?.attributes?.home_single_banner?.value
                ?.description_color && {
                style: {
                  color:
                    data?.attributes?.home_single_banner?.value
                      ?.description_color
                }
              })}
              className="camelcase mb-4 mt-1 text-center text-base font-normal md:text-lg lg:mb-4"
            >
              {data?.attributes?.home_single_banner?.value?.description}
            </p>
            {data?.attributes?.home_single_banner?.value?.url &&
              data?.attributes?.home_single_banner?.value?.button && (
                <Link
                  className={clsx(
                    'px-[35px] py-2',
                    'h-10',
                    'w-[120px]',
                    'bg-white',
                    'text-primary',
                    'transition-all',
                    'text-base',
                    'border border-primary'
                  )}
                  href={data?.attributes?.home_single_banner?.value?.url}
                  onClick={handleLinkClick}
                >
                  {data?.attributes?.home_single_banner?.value?.button}
                </Link>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
