/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useState, useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { RegisterClubCardFormType } from '@theme/types';
import { Button, Checkbox, Icon, Input, Select } from '@theme/components';
import * as yup from 'yup';
import { useLocalization } from '@akinon/next/hooks';
import PasswordRulesFeedback from '@theme/components/password-rules-feedback';
import {
  useGetCountriesQuery,
  useGetCitiesQuery,
  useGetTownshipsQuery
} from '@akinon/next/data/client/address';
import TextArea from '@theme/components/text-area';
import { generateBreadcrumbJsonLd } from '@theme/utils/generate-jsonld';

const registerFormSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('club_card_register.form.error.email_valid'))
      .required(t('club_card_register.form.error.required')),
    first_name: yup
      .string()
      .required(t('club_card_register.form.error.required'))
      .min(2, t('club_card_register.form.error.name_min'))
      .max(50, t('club_card_register.form.error.name_max'))
      .matches(
        /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/,
        t('club_card_register.form.error.name_match')
      ),
    last_name: yup
      .string()
      .required(t('club_card_register.form.error.required'))
      .min(2, t('club_card_register.form.error.surname_min'))
      .max(50, t('club_card_register.form.error.surname_max'))
      .matches(
        /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/,
        t('club_card_register.form.error.surname_match')
      ),
    password: yup
      .string()
      .required(t('club_card_register.form.error.required'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{6,}$/,
        t('club_card_register.form.error.password_rule')
      )
      .max(50, t('club_card_register.form.error.password_max')),
    password_confirm: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('club_card_register.form.error.password_match')
      ),
    phone: yup
      .string()
      .transform((value: string) => value.replace(/[()\s\-_+9]/g, ''))
      .required(t('club_card_register.form.error.required')),
    confirm: yup
      .boolean()
      .oneOf([true], t('club_card_register.form.error.required')),
    kvkk_confirm: yup
      .boolean()
      .oneOf([true], t('club_card_register.form.error.required')),
    sms_confirm: yup.boolean(),
    email_confirm: yup.boolean(),
    call_confirm: yup.boolean(),
    subscription_confirm: yup.boolean(),
    date_of_birth: yup
      .string()
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-9]{2})$/,
        t('club_card_register.form.error.birthdate_error')
      )
      .nullable()
  });

type SelectOptionType = {
  label: string;
  value: string | number;
};

export const ClubCardRegister = () => {
  const { t } = useLocalization();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm<RegisterClubCardFormType>({
    resolver: yupResolver(registerFormSchema(t))
  });

  const passwordValue = watch('password', '');

  const [formError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [callChecked, setCallChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [communicationChecked, setCommunicationChecked] = useState(false);

  const selectedCountry = watch('country');
  const selectedCity = watch('city');

  const { data: country } = useGetCountriesQuery();
  const { data: city } = useGetCitiesQuery('1', {
    skip: !selectedCountry
  });
  const { data: township } = useGetTownshipsQuery(selectedCity, {
    skip: !selectedCity
  });

  const cityOptions = useMemo(() => {
    if (city) {
      const options: SelectOptionType[] = [
        {
          label: t('account.address_book.form.province.placeholder'),
          value: ''
        }
      ];
      options.push(
        ...city.results.map((item) => ({ label: item.name, value: item.pk }))
      );
      return options;
    }
    return [];
  }, [city]);

  const townshipOptions = useMemo(() => {
    if (township) {
      const options: SelectOptionType[] = [
        {
          label: t('account.address_book.form.township.placeholder'),
          value: ''
        }
      ];
      options.push(
        ...township.results.map((item) => ({
          label: item.name,
          value: item.pk
        }))
      );
      return options;
    }
    return [];
  }, [township]);

  useEffect(() => {
    setValue('country', '1');
  }, [country, reset]);

  useEffect(() => {
    if (smsChecked || callChecked || emailChecked) {
      setCommunicationChecked(true);
    } else {
      setCommunicationChecked(false);
    }
  }, [smsChecked, callChecked, emailChecked]);

  useEffect(() => {
    if (communicationChecked) {
      setEmailChecked(true);
      setCallChecked(true);
      setSmsChecked(true);
    } else {
      setEmailChecked(false);
      setCallChecked(false);
      setSmsChecked(false);
    }
  }, [communicationChecked]);
  const genderOptions = () => {
    const genders = [];

    genders.push(
      {
        label: t('club_card_register.form.info.male'),
        value: 'male'
      },
      {
        label: t('club_card_register.form.info.female'),
        value: 'female'
      }
    );

    return genders;
  };

  const maritalOptions = () => {
    const maritals = [];

    maritals.push(
      {
        label: t('club_card_register.form.info.married'),
        value: 'married'
      },
      {
        label: t('club_card_register.form.info.single'),
        value: 'single'
      }
    );

    return maritals;
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    //TODO: submit handler should be implemented
  };
  const breadcrumbJsonLd = generateBreadcrumbJsonLd([
    {
      label: t('club_card_register.form.title'),
      url: '/club-card-register'
    }
  ]);
  return (
    <section className="w-full">
      <div className="mb-8 text-primary">
        <h2 className="mb-2 text-xl  leading-[30x] md:text-[32px] lg:mb-2 lg:leading-[44px]">
          {t('club_card_register.form.title')}
        </h2>

        <h3 className="text-sm">{t('club_card_register.form.description')}</h3>
      </div>

      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="font-sans text-lg font-medium md:text-2xl">
          {t('club_card_register.form.info.personal_info')}
        </div>

        <div className="lg:flex">
          <div className="w-full lg:pr-2">
            <Input
              labelStyle="default"
              label={t('club_card_register.form.info.name')}
              className="border-lightBlue-600 !text-base"
              inputClassName="!px-4 placeholder:!text-sm"
              labelClassName="text-gray !mb-[6px] font-medium"
              errorClassName="!mt-[6px]"
              {...register('first_name')}
              error={errors.first_name}
              data-testid="register-name"
              required
            />
          </div>

          <div className="mt-6 w-full lg:mt-0 lg:pl-2">
            <Input
              labelStyle="default"
              label={t('club_card_register.form.info.surname')}
              className="border-lightBlue-600 !text-base"
              inputClassName="!px-4 placeholder:!text-sm"
              labelClassName="text-gray !mb-[6px] font-medium"
              errorClassName="!mt-[6px]"
              {...register('last_name')}
              error={errors.last_name}
              data-testid="register-surname"
              required
            />
          </div>
        </div>

        <div>
          <Input
            labelStyle="default"
            label={t('club_card_register.form.info.email')}
            placeholder={t('auth.register.form.email.placeholder')}
            className="border-lightBlue-600 ps-12 !text-base"
            errorClassName="!mt-[6px]"
            labelClassName="text-gray !mb-[6px] font-medium"
            inputClassName="placeholder:!text-sm"
            {...register('email')}
            error={errors.email}
            data-testid="register-email"
            icon="pz-icon-mail"
            iconClassName="text-gray left-5"
            required
          />
        </div>

        <div>
          <Input
            labelStyle="default"
            label={t('club_card_register.form.info.phone')}
            className="border-lightBlue-600 ps-12 !text-base"
            errorClassName="!mt-[6px]"
            labelClassName="text-gray !mb-[6px] font-medium"
            inputClassName="placeholder:!text-sm"
            format="+90 (5##) ### ## ##"
            mask="_"
            control={control}
            {...register('phone')}
            error={errors.phone}
            data-testid="register-phone"
            icon="pz-icon-phone"
            iconClassName="text-gray text-2xl !bottom-[-15px]"
            required
            allowEmptyFormatting={true}
          />
        </div>

        <div>
          <label className="!mb-[6px] text-sm font-medium text-gray">
            <span>{t('club_card_register.form.info.date_of_birth')}</span>
            <span>({t('club_card_register.form.info.optional')})</span>
          </label>
          <Input
            labelStyle="default"
            placeholder={t('auth.register.form.birthday.placeholder')}
            className="mb-[6px] mt-[6px] border-lightBlue-600 ps-12 !text-base"
            inputClassName="placeholder:!text-sm tracking-widest"
            {...register('date_of_birth')}
            icon="pz-icon-calender"
            error={errors.date_of_birth}
            control={control}
            iconClassName="text-gray text-2xl !bottom-[-9px]"
            format="##/##/####"
          />
          <span className="!mb-[6px] text-sm font-medium text-gray">
            {t('club_card_register.form.info.birthday_info')}
          </span>
        </div>

        <div className="flex w-full gap-2">
          <Select
            className="border-lightBlue-600 bg-white text-black"
            options={genderOptions()}
            label={t('club_card_register.form.info.gender')}
            labelClassName="w-1/2"
          />
          <Select
            className="border-lightBlue-600 bg-white text-black"
            options={maritalOptions()}
            label={t('club_card_register.form.info.merital_status')}
            labelClassName="w-1/2"
          />
        </div>

        <div className="w-full border-b border-lightBlue-500"></div>

        <div className="font-sans text-lg font-medium md:text-2xl">
          {t('club_card_register.form.address.title')}
        </div>

        <div className="flex w-full gap-2">
          <Select
            className="border-lightBlue-600 bg-white text-black"
            labelClassName={clsx('w-full text-sm', township && 'w-1/2')}
            options={cityOptions}
            {...register('city')}
            error={errors.city}
            data-testid="address-form-city"
            label={t('club_card_register.form.address.city')}
            required
          />

          {township && (
            <Select
              className="border-lightBlue-600 bg-white text-black"
              labelClassName="w-1/2 border-gray-500 text-sm"
              options={townshipOptions}
              {...register('township')}
              error={errors.township}
              data-testid="address-form-township"
              label={t('club_card_register.form.address.township')}
              required
            />
          )}
        </div>

        <div>
          <TextArea
            labelClassName="mb-1.5 block text-sm leading-normal text-gray-800 lg:leading-tight"
            {...register('line')}
            label={t('club_card_register.form.address.address')}
            className={clsx(
              ' mt-2 block w-full border border-lightBlue-600 p-2 text-primary placeholder:text-gray-700'
            )}
            required
            maxLength={280}
            characterCount
            error={errors.line}
            rows={6}
            data-testid="address-form-address-field"
            placeholder={t('club_card_register.form.address.address')}
          />
        </div>

        <div className="w-full border-b border-lightBlue-500"></div>

        <div className="font-sans text-lg font-medium md:text-2xl">
          {t('club_card_register.form.password.title')}
        </div>

        <div className="relative">
          <div className="relative">
            <Input
              labelStyle="default"
              label={t('club_card_register.form.password.password')}
              placeholder={t(
                'club_card_register.form.password.password_placeholder'
              )}
              className="mb-[6px] border-lightBlue-600 ps-12 !text-base"
              labelClassName="text-gray !mb-[6px] font-medium"
              errorClassName="!mt-[6px]"
              inputClassName="placeholder:!text-sm"
              type={showPassword ? 'text' : 'password'}
              {...register('password')}
              data-testid="register-password"
              icon="pz-icon-lock"
              error={errors.password}
              iconClassName="text-gray text-2xl !bottom-[-10px]"
              required
            />
            <Icon
              size={25}
              className="absolute bottom-[-34px] right-4 flex h-full cursor-pointer !text-base"
              name={showPassword ? 'eye-on' : 'eye-off'}
              onClick={() => setShowPassword(!showPassword)}
            />
            <PasswordRulesFeedback password={passwordValue} isVisible={true} />
          </div>
        </div>

        <div className="relative border-b pb-8">
          <div className="relative">
            <Input
              labelStyle="default"
              label={t('club_card_register.form.password.password_repeat')}
              placeholder={t(
                'club_card_register.form.password.password_repeat_placeholder'
              )}
              className="mb-[6px] border-lightBlue-600 ps-12 !text-base"
              labelClassName="text-gray !mb-[6px] font-medium"
              errorClassName="!mt-[6px]"
              inputClassName="placeholder:!text-sm"
              type={showPassword ? 'text' : 'password'}
              {...register('password_confirm')}
              data-testid="register-password_confirm"
              icon="pz-icon-lock"
              error={errors.password_confirm}
              iconClassName="text-gray text-2xl !bottom-[-10px]"
              required
            />
            <Icon
              size={25}
              className="absolute bottom-[-34px] right-4 flex h-full cursor-pointer !text-base"
              name={showPassword ? 'eye-on' : 'eye-off'}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>

        <div className="!text-sm text-black-400 md:text-xs">
          <Checkbox
            className={clsx('font-medium', errors.confirm ? 'mb-8' : 'mb-6')}
            {...register('kvkk_confirm')}
            error={errors.kvkk_confirm}
            data-testid="register-agreement-1"
            wrapperClassName="!items-start"
            labelClassName="text-primary"
          >
            <span className="cursor-pointer underline">
              {t('club_card_register.form.agreements.club_card_agreement')}
            </span>{' '}
            {t('club_card_register.form.agreements.club_card_agreement_2')}{' '}
            <span className="cursor-pointer underline">
              {t('club_card_register.form.agreements.kvkk_agreement')}
            </span>{' '}
            {t('club_card_register.form.agreements.kvkk_agreement_2')}
          </Checkbox>

          <Checkbox
            className={clsx(
              'font-medium',
              errors.kvkk_confirm ? 'mb-8' : 'mb-6'
            )}
            {...register('confirm')}
            error={errors.confirm}
            data-testid="register-agreement-2"
            wrapperClassName="!items-start"
            labelClassName="text-primary"
          >
            {t('auth.register.form.agreements.kvkk.label')}
          </Checkbox>

          <Checkbox
            className="mb-2 items-start font-medium"
            data-testid="register-agreement-3"
            onChange={() => setCommunicationChecked(!communicationChecked)}
            checked={communicationChecked}
            wrapperClassName="!items-start"
            labelClassName="text-primary"
          >
            {t('auth.register.form.agreements.email_communication.label')}

            <div className="mt-4 flex gap-x-6">
              <Checkbox
                {...register('sms_allowed')}
                onChange={() => setSmsChecked(!smsChecked)}
                checked={smsChecked}
                className="items-start font-medium"
                data-testid="register-agreement-3"
                labelClassName="text-primary"
              >
                {t('auth.register.form.agreements.contact.sms')}
              </Checkbox>
              <Checkbox
                {...register('email_allowed')}
                onChange={() => setEmailChecked(!emailChecked)}
                checked={emailChecked}
                className="items-start font-medium"
                data-testid="register-agreement-3"
                labelClassName="text-primary"
              >
                {t('auth.register.form.agreements.contact.email')}
              </Checkbox>
              <Checkbox
                {...register('call_allowed')}
                onChange={() => setCallChecked(!callChecked)}
                checked={callChecked}
                className="items-start font-medium"
                data-testid="register-agreement-3"
                labelClassName="text-primary"
              >
                {t('auth.register.form.agreements.contact.phone')}
              </Checkbox>
            </div>
          </Checkbox>
        </div>

        <label className="mb-2 hidden text-error"></label>

        {formError && <p className="text-xs text-error">{formError}</p>}

        <Button className="h-12 w-full" type="submit">
          {t('auth.register.form.submit')}
        </Button>
      </form>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }}
      />
    </section>
  );
};
